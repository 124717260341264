import React, { useEffect, useState } from "react";
import logo from "../images/inluvLogo.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { axiosCall } from "../utils/axiosCall";
import { setToken } from "../redux/features/userSlice";

const defaultTheme = createTheme();
function VerifyMail() {
  const [OTP, setOTP] = useState("");

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Inluv Admin | Verify mail";
  }, []);

  const handleSubmit = async () => {
    try {
      const otp = await axiosCall("POST", "/admin/v1/verifyOtp", {
        otp: OTP,
        userId: user.id,
      });

      if (otp.isSuccess) {
        dispatch(setToken({ token: otp.data?.data?.token }));
        alert("otp matched!!!");
        navigate("/reset-password");
      } else {
        alert(otp.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="h1" variant="h5" sx={{ fontWeight: "600" }}>
            Verify your email
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            sx={{ fontSize: "16px", mt: 2, textAlign: "center" }}
          >
            Please enter the 6 digit code sent to your email id.
          </Typography>
          <Box
            // component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            // action="/reset-password"
          >
            <TextField
              margin="normal"
              //   required
              fullWidth
              name="password"
              label="Enter OTP"
              type="password"
              id="oneTimePassword"
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
            />
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, p: 1.5 }}
              style={{ backgroundColor: "var(--primaryColor)" }}
              onClick={handleSubmit}
            >
              Reset Password
            </Button>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

export default VerifyMail;

import axios from "axios";
import config from "../config/config";

const generateResponse = (isSuccess, data, error) => {
  return {
    isSuccess,
    data,
    error,
  };
};

export const axiosCall = async (method, url, data, params, token) => {
  try {
    let headers = {};
    if (token) {
      headers = { Authorization: `Bearer ${token}` };
    }
    let response = await axios({
      method,
      url: `${config.server.url}/apis/${url}`,
      data,
      params,
      headers,
    });
    if (response.status === 200) {
      return generateResponse(true, response.data);
    }
  } catch (error) {
    if (
      error.response.status === 400 ||
      error.response.status === 404 ||
      error.response.status === 401 ||
      error.response.status === 409 ||
      error.response.status === 500
    ) {
      if (error.response.data) {
        return generateResponse(false, null, error.response.data);
      }
    }
  }
};

import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useOutletContext } from "react-router-dom";
import PlansCard from "../components/PlansCard";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PlanModal from "../components/PlanModal";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import PlansCardSkeleton from "../components/skeletons/PlansCardSkeleton";

function Plan() {
  const [open, drawerWidth] = useOutletContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedPlan, setSelectedPlan] = useState({});
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    document.title = "Inluv Admin | Plans";
  }, []);

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    setLoading(true);
    try {
      const plansRes = await axiosCall(
        "GET",
        "plans/v1/",
        null,
        null,
        user.token
      );

      if (plansRes.isSuccess) {
        setPlans(plansRes.data?.data);
        setLoading(false);
      } else {
        alert(plansRes.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        // p: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          py: 1.5,
          px: "4rem",
          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Typography
          variant="h5"
          color="#000"
          component="h4"
          sx={{
            fontWeight: "700",
            mb: 1,
            // textAlign: "center",
          }}
        >
          Subscription Plans
        </Typography>
        <Typography
          variant="p"
          color="#555"
          component="p"
          sx={{
            display: { xs: "block", sm: "block" },
            mb: 4,
            // textAlign: "center",
            lineHeight: 1,
          }}
        >
          You can add or edit the plans that will reflect in the mobile
          application
        </Typography>
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            backgroundColor: "var(--primaryColor)",
            textTransform: "none",
            right: 25,
          }}
          onClick={() => {
            setModalType("add");
            handleOpen();
          }}
        >
          <AddIcon
            sx={{
              fontSize: "15px",
              marginRight: "10px",
            }}
          />
          Add New Plan
        </Button>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minHeight: "70vh",
          }}
        >
          {!loading ? (
            plans?.map((plan, i) => {
              return (
                <PlansCard
                  key={i}
                  plan={plan}
                  handleOpen={handleOpen}
                  setModalType={setModalType}
                  setSelectedPlan={setSelectedPlan}
                />
              );
            })
          ) : (
            <Box sx={{ display: "flex" }}>
              {plans.map((i) => (
                <PlansCardSkeleton key={i._id} />
              ))}
            </Box>
          )}
        </Box>
        <PlanModal
          modalOpen={modalOpen}
          handleClose={handleClose}
          getPlans={getPlans}
          modalType={modalType}
          // setModalType={setModalType}
          selectedPlan={selectedPlan}
        />
      </Paper>
    </Box>
  );
}

export default Plan;

import { Avatar, Box, Card, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import OptionMenu from "../components/OptionMenu";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PaymentsIcon from "@mui/icons-material/Payments";

const headCells = [
  {
    id: "profile",
    label: "Profile",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "plan",
    label: "Plan",
  },
  {
    id: "payment",
    label: "Payment",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "joiningDate",
    label: "Joining date",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#F0F9FF" }}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 600 }}
          >
            {headCell.label}
            <TableSortLabel></TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            fontSize: "20px",
            fontWeight: 600,
            color: "var(--primaryColor)",
          }}
          variant="h4"
          id="tableTitle"
          component="div"
        >
          Recent Payments
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>{/* <FilterListIcon /> */}</IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const duration = [
  { name: "Last 30 days", days: 31 },
  { name: "Last 6 months", days: 183 },
  { name: "Last 1 year", days: 365 },
  { name: "All time", days: 0 },
];

const cardStyle = {
  mt: 2,
  mr: 3,
  px: 1.5,
  height: "fitContent",
  display: "flex",
  alignItems: "center",
  minWidth: 275,
};

function Payments() {
  const [open, drawerWidth] = useOutletContext();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [plans, setPlans] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [paymentAnalytics, setPaymentAnalytics] = useState({
    totalRevenue: "",
    totalSubscription: "",
  });
  const [selectedDuration, setSelectedDuration] = useState();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    document.title = "Inluv Admin | Payments";
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allUsers?.payments?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allUsers?.userCount) : 0;

  const getPlans = async () => {
    try {
      const plansRes = await axiosCall(
        "GET",
        "/plans/v1/",
        null,
        null,
        user.token
      );

      if (plansRes.isSuccess) {
        setPlans(plansRes.data?.data);
      } else {
        alert(plansRes.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const getData = async (days) => {
    try {
      const stats = await axiosCall(
        "GET",
        "payments/v1/analytics",
        null,
        { days },
        user.token
      );
      if (stats.data.isSuccess) {
        setPaymentAnalytics((prev) => ({
          ...prev,
          totalRevenue: stats.data.data[0]?.totalRevenue,
          totalSubscription: stats.data.data[0]?.count,
        }));
        getUsers(days);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getUsers = async (days = 31) => {
    try {
      const userRes = await axiosCall(
        "GET",
        "payments/v1/users",
        null,
        { days, offset: page * 10 },
        user.token
      );
      if (userRes.data.isSuccess) {
        setAllUsers(userRes.data?.data);
      } else {
        console.log(userRes.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 1,
        width: "98.2vw",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          py: 1.5,
          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Typography
          variant="h5"
          color="var(--primaryColor)"
          component="h6"
          sx={{
            fontWeight: "700",
            fontSize: "20px",
            mb: 2,
          }}
        >
          Payment Statistics
        </Typography>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-end",
            mb: 3,
            position: "absolute",
            right: 8,
          }}
        >
          <OptionMenu
            options={duration}
            getData={getData}
            selectedDuration={selectedDuration}
            setSelectedDuration={setSelectedDuration}
          />
          {/* <OptionMenu options={plans} getData={getData} /> */}
        </Box>
        <Box sx={{ display: "flex", mt: 2 }}>
          <Card sx={cardStyle}>
            <Box
              sx={{
                px: 2.5,
                py: 2,
                borderRadius: 1,
                bgcolor: "#52d5ba",
              }}
            >
              <SignalCellularAltIcon sx={{ fontSize: 42, color: "#fff" }} />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="text.primary"
              >
                Total Revenue
              </Typography>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 1, fontWeight: 700 }}
              >
                {paymentAnalytics.totalRevenue
                  ? `$ ${paymentAnalytics.totalRevenue}`
                  : 0}
              </Typography>
              {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ mt: 1, pb: 1 }} color="text.primary">
                  Today {"$146.00"}
                </Typography>
                <Button size="small" sx={{ p: 0 }}>
                  <ChevronRightRoundedIcon />
                </Button>
              </Box> */}
            </Box>
          </Card>
          <Card sx={cardStyle}>
            <Box
              sx={{
                px: 2.5,
                py: 2,
                borderRadius: 1,
                bgcolor: "#ff4267",
              }}
            >
              <PaymentsIcon sx={{ fontSize: 42, color: "#fff" }} />
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{ fontSize: 16, fontWeight: 600 }}
                color="text.primary"
              >
                Total Subscription
              </Typography>
              <Typography
                variant="h3"
                component="div"
                sx={{ mt: 1, fontWeight: 700 }}
              >
                {paymentAnalytics.totalSubscription
                  ? `${paymentAnalytics.totalSubscription}`
                  : 0}
              </Typography>
              {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ mt: 1, pb: 1 }} color="text.primary">
                  Today {"5"}
                </Typography>
                <Button size="small" sx={{ p: 0 }}>
                  <ChevronRightRoundedIcon />
                </Button>
              </Box> */}
            </Box>
          </Card>
        </Box>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          py: 1.5,
          display: "flex",
          flexDirection: "column",
          // padding: 2,
          mt: 1.5,
        }}
      >
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={allUsers?.payments?.length}
            />
            <TableBody>
              {allUsers?.payments?.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                const date = new Date(row.createdAt);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ pl: 2 }}
                    >
                      {row.avatar ? (
                        <Avatar alt="Remy Sharp" src={row.path} />
                      ) : (
                        <Avatar alt={row.userId.fullName} src="/" />
                      )}
                    </TableCell>
                    <TableCell align="left">{row.userId.fullName}</TableCell>
                    <TableCell align="left">
                      {row.plan?.name ? row.plan?.name : "N/A"}
                    </TableCell>
                    <TableCell align="left">{row.amount}</TableCell>
                    <TableCell align="left">
                      {row?.status ? row?.status : "Pending"}
                    </TableCell>
                    <TableCell align="left">
                      {date.toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={allUsers?.userCount || 10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default Payments;

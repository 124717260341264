import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import DataTable from "../components/DataTable";
import { useSelector } from "react-redux";
import { axiosCall } from "../utils/axiosCall";
import { useOutletContext } from "react-router-dom";
import UserProfileDrawer from "../components/UserProfileDrawer";

function Home() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [searchUser, setSearchUser] = useState();
  const [isUserDrawerOpen, setIsUserDrawerOpen] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [drawerUserId, setDrawerUserId] = useState();

  const user = useSelector((state) => state.user);

  const [openSideNav, drawerWidth, selectedUser, setSelectedUser] =
    useOutletContext();

  useEffect(() => {
    document.title = "Inluv Admin | Users";
  }, []);

  const getUsers = async (sortingOrder = 1) => {
    try {
      const userRes = await axiosCall(
        "GET",
        "admin/v1/users",
        null,
        {
          offset: page * 10,
          sortingOrder,
          searchTerm: searchUser,
        },
        user.token
      );
      if (userRes.data.isSuccess) {
        setUsers(userRes.data?.data);
      } else {
        console.log(userRes.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page, searchUser]);

  const handleUserDetails = async (userId) => {
    try {
      setLoading(true);
      const userDetails = await axiosCall(
        "GET",
        "admin/v1/user",
        null,
        {
          userId: userId,
        },
        user.token
      );
      if (userDetails.data.isSuccess) {
        // console.log(userDetails);
        setUserData(userDetails?.data?.data);
        setLoading(false);
        // setIsUserDrawerOpen(true);
      } else {
        console.log(userDetails.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Banner />
      <DataTable
        users={users}
        setSelectedUser={setSelectedUser}
        selectedUser={selectedUser}
        page={page}
        setPage={setPage}
        isUserDrawerOpen={isUserDrawerOpen}
        setIsUserDrawerOpen={setIsUserDrawerOpen}
        searchUser={searchUser}
        setSearchUser={setSearchUser}
        getUsers={getUsers}
        handleUserDetails={handleUserDetails}
        userData={userData}
        setDrawerUserId={setDrawerUserId}
      />

      <UserProfileDrawer
        isUserDrawerOpen={isUserDrawerOpen}
        setIsUserDrawerOpen={setIsUserDrawerOpen}
        userData={userData}
        loading={loading}
        drawerUserId={drawerUserId}
        handleUserDetails={handleUserDetails}
        getUsers={getUsers}
      />
    </>
  );
}

export default Home;

import React from "react";
import logo from "../images/inluvWhite.png";
import avatar from "../images/avatar.jpg";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import SideNavMenu from "./SideNavMenu";
import Typography from "@mui/material/Typography";
import { logout } from "../redux/features/userSlice";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function SideBar(props) {
  const { open, setOpen, drawerWidth, setSelectedMenu } = props;
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      dispatch(logout());
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => {
        setOpen(!open);
        localStorage.setItem("openStatus", !open);
      }}
    >
      <Box
        className="sideNav"
        sx={{
          width: drawerWidth,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          size="large"
          edge="start"
          aria-label="open drawer"
          sx={{ position: "absolute", left: 24, top: 8 }}
          onClick={() => {
            setOpen(!open);
            localStorage.setItem("openStatus", !open);
          }}
        >
          <MenuIcon sx={{ color: "#fff" }} />
        </IconButton>
        <img
          src={logo}
          className="logoWhite"
          alt="logo"
          style={{
            height: "75px",
            width: "120px",
            marginLeft: "54px",
            marginTop: "-8px",
            opacity: open ? 1 : 0,
            position: "absolute",
          }}
        />
        <ListItem sx={{ mt: 10 }}>
          <ListItemAvatar>
            <Avatar alt="Talia Aloush" src={avatar} sx={{}} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{
                  flex: "1 1 100%",
                  fontWeight: "600",
                  ml: 0.5,
                  fontSize: 12,
                }}
                variant="h5"
                component="p"
              >
                Talia Aloush
              </Typography>
            }
            secondary={
              <Typography
                sx={{
                  flex: "1 1 100%",
                  fontWeight: "600",
                  ml: 0.5,
                  color: "#aaa",
                  fontSize: "10px",
                }}
                variant="h6"
                component="p"
              >
                Admin
              </Typography>
            }
          />
        </ListItem>
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <SideNavMenu
            open={open}
            setOpen={setOpen}
            setSelectedMenu={setSelectedMenu}
          />
          <Button
            variant="contained"
            startIcon={<LogoutIcon />}
            sx={{
              color: "#000",
              backgroundColor: "#fff",
              fontWeight: "700",
              my: 5,
              marginInline: "25px",
            }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default SideBar;

const config = {
  server: {
    // url: "https://5524-2405-201-8016-3055-87c-8f84-f32e-17dc.ngrok-free.app",
    // url: "http://35.171.184.107:8001",
    url: "https://inluvadmincheck.com",
    // url: "http://localhost:8001",
  },
};

export default config;

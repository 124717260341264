import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EventCard from "../components/EventCard";
import AddEventModal from "../components/AddEventModal";
import EventDetailsModal from "../components/EventDetailsModal";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import EventCardSkeleton from "../components/skeletons/EventCardSkeleton";
import Loader from "../components/Loader";

function Events() {
  const [addEventModalOpen, setAddEventModalOpen] = useState(false);
  const [eventDetailModalOpen, setEventDetailModalOpen] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedEvent, setSelectedEvent] = useState();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const handleOpenAddEventModal = () => setAddEventModalOpen(true);
  const handleCloseAddEventModal = () => setAddEventModalOpen(false);

  const handleOpenEventDetailModal = () => setEventDetailModalOpen(true);
  const handleCloseEventDetailModal = () => setEventDetailModalOpen(false);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    setLoading(true);
    try {
      const eventResponse = await axiosCall(
        "GET",
        "/events/v1/",
        null,
        null,
        user.token
      );

      if (eventResponse.isSuccess) {
        setEvents(eventResponse.data?.data);
        setLoading(false);
      } else {
        alert(eventResponse.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    document.title = "Inluv Admin | Events";
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
        }}
      >
        <Paper
          elevation={0}
          sx={{
            py: 1.5,
            px: "4rem",
            display: "flex",
            flexDirection: "column",
            padding: 2,
          }}
        >
          <Typography
            variant="h5"
            color="#000"
            component="h6"
            sx={{
              fontWeight: "700",
              fontSize: "20px",
              mb: 2,
            }}
          >
            Upcoming Events
          </Typography>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              backgroundColor: "var(--primaryColor)",
              right: 25,
              textTransform: "none",
            }}
            onClick={() => {
              setModalType("add");
              handleOpenAddEventModal();
            }}
          >
            <AddIcon
              sx={{
                fontSize: "15px",
                marginRight: "10px",
              }}
            />
            Add New Event
          </Button>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {!loading ? (
              events?.upcomingEvents?.map((event, i) => (
                <EventCard
                  key={i}
                  event={event}
                  handleOpen={handleOpenEventDetailModal}
                  setModalType={setModalType}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                />
              ))
            ) : (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <EventCardSkeleton />
                <EventCardSkeleton />
                <EventCardSkeleton />
              </Box>
            )}
          </Box>
          <Typography
            variant="h5"
            color="#000"
            component="h6"
            sx={{
              fontWeight: "700",
              fontSize: "20px",
              my: 2,
            }}
          >
            Past Events
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {!loading ? (
              events?.pastEvents?.map((event, i) => (
                <EventCard
                  key={i}
                  event={event}
                  handleOpen={handleOpenEventDetailModal}
                  setModalType={setModalType}
                  selectedEvent={selectedEvent}
                  setSelectedEvent={setSelectedEvent}
                />
              ))
            ) : (
              <Box sx={{ display: "flex" }}>
                <EventCardSkeleton />
                <EventCardSkeleton />
                <EventCardSkeleton />
              </Box>
            )}
          </Box>
          <AddEventModal
            modalOpen={addEventModalOpen}
            handleCloseAddEventModal={handleCloseAddEventModal}
            getEvents={getEvents}
            selectedEvent={selectedEvent}
            modalType={modalType}
            handleCloseEventDetailModal={handleCloseEventDetailModal}
          />
          <EventDetailsModal
            modalOpen={eventDetailModalOpen}
            setModalType={setModalType}
            selectedEvent={selectedEvent}
            handleCloseEventDetailModal={handleCloseEventDetailModal}
            handleOpenAddEventModal={handleOpenAddEventModal}
          />
        </Paper>
      </Box>
    </>
  );
}

export default Events;

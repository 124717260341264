import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPass from "./pages/ForgotPass";
import ResetPass from "./pages/ResetPass";
import Signin from "./pages/Signin";
import Users from "./pages/Users";
import PrivateRoute from "./PrivateRoute";
import Verify from "./pages/VerifyMail";
import PageNotFound from "./pages/PageNotFound";
import DashboardLayout from "./components/DashboardLayout";
import Plan from "./pages/Plan";
import Matches from "./pages/Matches";
import Events from "./pages/Events";
import Payments from "./pages/Payments";
import Dashboard from "./pages/Dashboard";
import TermsAndCond from "./pages/Terms/TermsAndCond";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Signin />} />
        <Route exact path="/forgot-password" element={<ForgotPass />} />
        <Route exact path="/reset-password" element={<ResetPass />} />
        <Route exact path="/verify-mail" element={<Verify />} />
        <Route exact path="/terms" element={<TermsAndCond />} />
        <Route path="" element={<DashboardLayout />}>
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/plans"
            element={
              <PrivateRoute>
                <Plan />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/matches"
            element={
              <PrivateRoute>
                <Matches />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/events"
            element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payment"
            element={
              <PrivateRoute>
                <Payments />
              </PrivateRoute>
            }
          />
          <Route exact path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

// function App() {
//   return (
//     <Box width="100%" height="100vh" fontFamily="Inter">
//       <BrowserRouter>
//         <Routes>
//           <Route exact path="/" element={<Login />} />
//           <Route exact path="/register" element={<Register />} />
//           <Route
//             exact
//             path="/home"
//             element={
//               <PrivateRoute>
//                 <Home />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             exact
//             path="/profile/:username"
//             element={
//               <PrivateRoute>
//                 <Profile />
//               </PrivateRoute>
//             }
//           />
//           <Route
//             exact
//             path="/thought/:thought_id"
//             element={
//               <PrivateRoute>
//                 <Thought />
//               </PrivateRoute>
//             }
//           />
//         </Routes>
//       </BrowserRouter>
//     </Box>
//   );
// }

export default App;

import { Box, Card, Skeleton } from "@mui/material";
import React from "react";

function EventCardSkeleton() {
  return (
    <Card sx={{ width: 370, mr: 4, my: 2, borderRadius: 1.5 }}>
      <Skeleton variant="rectangular" width="100%" height={200} />
      <Skeleton sx={{ mx: 2, mt: 2 }} />
      <Skeleton sx={{ mx: 2 }} />
      <Box sx={{ display: "flex", pl: 2, pb: 2 }}>
        <Skeleton width="30%" sx={{ mr: 2 }} />
        <Skeleton width="30%" />
      </Box>
    </Card>
  );
}

export default EventCardSkeleton;

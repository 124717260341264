import React, { useEffect, useState } from "react";
import logo from "../images/inluvLogo.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { axiosCall } from "../utils/axiosCall";
import { logout } from "../redux/features/userSlice";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import SnackbarError from "../components/SnackbarError";

const defaultTheme = createTheme();
function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}
function ResetPass() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [error, setError] = useState();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Inluv Admin | Reset passsword";
  }, []);

  const handleSubmit = async () => {
    try {
      if (password !== confirmPassword) {
        alert("Passwords must match");
        return;
      }

      const passwordResponse = await axiosCall(
        "POST",
        "/admin/v1/createPassword",
        { password },
        null,
        user.token
      );

      if (passwordResponse.isSuccess) {
        dispatch(logout());
        // alert("Password updated!!! Please login");
        setOpenSuccessSnackbar(true);
        setTimeout(() => {
          navigate("/");
        }, 4000);
      } else {
        setError(passwordResponse.error?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="h1" variant="h5" sx={{ fontWeight: "600" }}>
            Reset Password
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            sx={{ fontSize: "16px", mt: 2, textAlign: "center" }}
          >
            Kindly enter your new password.
          </Typography>
          <Box
            // component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, p: 1.5 }}
              style={{ backgroundColor: "var(--primaryColor)" }}
              disabled={!password || !confirmPassword}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSuccessSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSuccessSnackbar(false)}
            TransitionComponent={TransitionDown}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="success"
              onClose={() => setOpenSuccessSnackbar(false)}
            >
              Password updated!!! Please login...
            </MuiAlert>
          </Snackbar>

          <SnackbarError
            message={error}
            setOpenSnackbar={setOpenSnackbar}
            openSnackbar={openSnackbar}
          />
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}

export default ResetPass;

import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";

// const options = ["Remove", "Deactivate", "Block"];

const ITEM_HEIGHT = 48;

function KababMenu({ userId, isActive, getUsers }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector((state) => state.user);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetails = {
    userId: userId,
    type: isActive ? "deactivate" : "activate",
  };

  const handleDeactivateUser = async () => {
    try {
      const deactiveateRes = await axiosCall(
        "Post",
        "admin/v1/deactivateUser",
        userDetails,
        null,
        user.token
      );
      if (deactiveateRes.data.isSuccess) {
        getUsers();
        console.log(deactiveateRes.data?.message);
      } else {
        console.log(deactiveateRes.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 5,
            width: "13ch",
          },
        }}
      >
        {/* {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={handleClose}
          >
            {option}
          </MenuItem>
        ))} */}
        <MenuItem
          onClick={() => {
            handleClose();
            handleDeactivateUser();
          }}
        >
          {isActive ? "Deactivate" : "Activate"}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default KababMenu;

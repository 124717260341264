import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";

function TopNav(props) {
  const { open, setOpen, selectedMenu } = props;

  const handleDrawerOpen = () => {
    setOpen(!open);
    localStorage.setItem("openStatus", !open);
  };

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="#000"
          aria-label="open drawer"
          sx={{ mr: 2 }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="#000"
          noWrap
          component="h1"
          sx={{ display: { xs: "block", sm: "block", fontWeight: "700" } }}
        >
          {selectedMenu}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBar>
  );
}

export default TopNav;

import { Box, Card, Skeleton } from "@mui/material";
import React from "react";

function UpcomingEventCardSkeleton() {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 1.4,
        mt: 1,
        alignItems: "center",
      }}
    >
      <Skeleton width={66} height={62} />
      <Box
        sx={{ display: "flex", flexDirection: "column", ml: 2, width: "100%" }}
      >
        <Skeleton width="90%" height={16} />
        <Skeleton width={126} height={16} />
        <Skeleton width={126} height={16} />
      </Box>
    </Card>
  );
}

export default UpcomingEventCardSkeleton;

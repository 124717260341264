import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Avatar,
  Paper,
  Typography,
  Skeleton,
  Snackbar,
  Slide,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import MuiAlert from "@mui/material/Alert";
import StatusForm from "./StatusForm";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const paperStyle = {
  p: 1.5,
  background:
    "linear-gradient(to right top, #F0F9FF, rgba(255, 255, 255, 0.00))",
};

const userObj = {
  address: "Address",
  alcoholConsumption: "Alcohol Consumption",
  allergies: "Allergies",
  birthday: "Birth Date",
  cannabis: "Cannabis",
  dealBreakers: "Deal Breakers",
  describedBy: "Described By",
  divorced: "Divorced",
  education: "Education",
  email: "Email",
  exercise: "Exercise",
  favActivities: "Fav. Activities",
  fullName: "Full Name",
  gender: "Gender",
  haveChildren: "Children",
  havePets: "Pets",
  healthConscious: "Health Conscious",
  height: "Height",
  hometown: "Home Town",
  identifyAs: "Identify As",
  isProfileCompleted: "Profile Status",
  isVerified: "Verified",
  married: "Married",
  nicotineSmokinVapingConsumption: "Nicotine/ Smoking/ Vaping",
  occupation: "Occupation",
  openToMatchWithPeopleWhoDrink: "Match With People Who Drink",
  openToMatchWithPeopleWhoSmoke: "Match With People Who Smoke",
  openToMatchWithPeopleWhoUseCannabis: "Match With People Cannabins",
  openToMatchWithPeopleWithChildren: "Match With People With Children",
  petsDescription: "Pets Description",
  phone: "Phone No.",
  politicalBeliefs: "Political Beliefs",
  politicalViewsMatter: "Political Views Matter",
  position: "Position",
  preferredAgeRange: "Prefered Age Range",
  pronouns: "Pronouns",
  religionPreference: "Religion Preference",
  wantChildren: "Want Children",
  widowOrWidower: "Widow Or Widower",
  jewish: "Jewish",
  keepShabbat: "Keep Shabbat",
  keepKosher: "Keep Kosher",
  isFatherJewish: "Is Father Jewish",
  isMotherJewish: "Is Mother Jewish",
  languages: "Languages",
  religiousBackgroundNow: "Religious Background Now",
  racesEthnicitiesNationalities: "Races/Ethnicities/Nationalities",
  jewishCulturalRoots: "Jewish Cultural Roots",
  convertToJudaism: "Convert to Judaism",
};

const userCategories = {
  "Personal Information": [
    "gender",
    "birthday",
    "identifyAs",
    "pronouns",
    "height",
    "allergies",
  ],
  Location: ["address", "hometown"],
  "Work and Education": ["occupation", "education"],
  Description: ["describedBy"],
  Family: [
    "married",
    "divorced",
    "widowOrWidower",
    "haveChildren",
    "wantChildren",
  ],
  Lifestyle: ["havePets", "petsDescription", "politicalBeliefs", "exercise"],
  Substances: [
    "alcoholConsumption",
    "nicotineSmokinVapingConsumption",
    "cannabis",
  ],
  Interests: ["favActivities"],
  "Match Preferences": [
    "religionPreference",
    "openToMatchWithPeopleWithChildren",
    "politicalViewsMatter",
    "healthConscious",
  ],
  Choices: [
    "openToMatchWithPeopleWhoDrink",
    "openToMatchWithPeopleWhoSmoke",
    "openToMatchWithPeopleWhoUseCannabis",
  ],
  "Age Preference": ["preferredAgeRange"],
  Dealbreakers: ["dealBreakers"],
  Bio: [
    "jewish",
    "convertToJudaism",
    "jewishCulturalRoots",
    "racesEthnicitiesNationalities",
    "religiousBackgroundNow",
    "languages",
    "isFatherJewish",
    "isMotherJewish",
    "keepShabbat",
    "keepKosher",
  ],
};

function CustomTabPanel(props) {
  const { children, value, index, loading, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            px: 1,
            height: `calc(100vh - 350px)`,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading ? <CircularProgress sx={{ m: "auto" }} /> : children}
        </Box>
      )}
    </div>
  );
}

function DataItem({ label, value }) {
  return (
    <Paper elevation={0} sx={paperStyle}>
      <Typography
        sx={{ display: "inline" }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        <strong>{label}: </strong>{" "}
        {/* {typeof value === "boolean" ? String(value) : ""} */}
        {Array.isArray(value)
          ? value.join(", ")
          : label === "Birth Date"
          ? // `${new Date(value).getDate()}-${new Date(
            //     value
            //   ).getMonth()}-${new Date(value).getFullYear()}`
            `${new Date(value).toLocaleDateString("en-GB")}`
          : value}
      </Typography>
    </Paper>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function UserProfileDrawer(props) {
  const {
    isUserDrawerOpen,
    setIsUserDrawerOpen,
    userData,
    loading,
    drawerUserId,
    handleUserDetails,
    getUsers,
  } = props;
  const [value, setValue] = React.useState(0);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Drawer
      anchor="right"
      open={isUserDrawerOpen}
      onClose={() => setIsUserDrawerOpen(false)}
    >
      <Box sx={{ width: 380, overflow: "hidden" }} role="presentation">
        <Box
          sx={{
            height: "fitContent",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#F0F9FF",
            position: "relative",
          }}
        >
          {/* <CloseIcon sx={{ position: "absolute", right: 15, top: 15 }} /> */}
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{
                width: 100,
                height: "fitContent",
                mt: 3,
                ml: 3,
                borderRadius: "50%",
              }}
            />
          ) : (
            <Avatar
              alt={userData?.fullName}
              src={userData?.profile?.Location}
              sx={{
                width: 100,
                height: 100,
                position: "relative",
                mt: 3,
                ml: 3,
              }}
            />
          )}
          <Box sx={{ ml: 3, my: 2, position: "relative" }}>
            <Typography
              variant="h6"
              color="#000"
              component="h1"
              sx={{ fontWeight: 600 }}
            >
              {loading ? <Skeleton width={"93%"} /> : userData?.fullName}
            </Typography>
            {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography>
                <strong>Gender:</strong> {userData?.gender}
              </Typography>
            )}
            {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography>
                <strong>Education:</strong> {userData?.education}
              </Typography>
            )}
            {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography>
                <strong>Occupation:</strong> {userData?.occupation}
              </Typography>
            )}
            {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography>
                <strong>Home town</strong> {userData?.hometown}
              </Typography>
            )}
            {/* {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography>"{userData?.bioDescription}"</Typography>
            )} */}
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example"
              sx={{ width: "100%" }}
            >
              <Tab label="About" {...a11yProps(0)} />
              {/* <Tab label="Family & Lifestyle" {...a11yProps(1)} />
              <Tab label="Judaism" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel loading={loading} value={value} index={0}>
            <h4
              style={{
                marginBottom: "10px",
                color: "var(--primaryColor)",
                marginLeft: "5px",
              }}
            >
              About {userData?.fullName}
            </h4>
            {loading ? (
              <Skeleton width={"93%"} />
            ) : (
              <Typography sx={{ mx: 1, mt: 0, textAlign: "justify" }}>
                "{userData?.bioDescription}"
              </Typography>
            )}
            {Object.entries(userCategories).map(([key, value]) => (
              <>
                <h4
                  style={{
                    marginBottom: "10px",
                    color: "var(--primaryColor)",
                    marginLeft: "5px",
                  }}
                >
                  {key} :
                </h4>
                {userCategories[key].map((item) => (
                  <DataItem
                    key={key}
                    label={userObj[item]}
                    value={userData[item]}
                  />
                ))}
              </>
            ))}
            {/* {Object.entries(userData).map(([key, value]) => {
              if (userObj[key]) {
                return (
                  <DataItem key={key} label={userObj[key]} value={value} />
                );
              }
            })} */}
            {userData.interviewStatus === "Pending" ? (
              <StatusForm
                drawerUserId={drawerUserId}
                handleUserDetails={handleUserDetails}
                setOpenSuccessSnackbar={setOpenSuccessSnackbar}
                getUsers={getUsers}
              />
            ) : (
              <Paper
                elevation={1}
                sx={paperStyle}
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  <strong>Interview Status: </strong> {userData.interviewResult}
                </Typography>
              </Paper>
            )}
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={1}>
            Item Two
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            Item Three
          </CustomTabPanel> */}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnackbar(false)}
        TransitionComponent={TransitionDown}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => setOpenSuccessSnackbar(false)}
        >
          Response submitted successfully!
        </MuiAlert>
      </Snackbar>
    </Drawer>
  );
}

export default UserProfileDrawer;

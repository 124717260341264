import React, { useEffect } from "react";

function PageNotFound() {
  useEffect(() => {
    document.title = "Inluv Admin | 404 Error";
  }, []);
  return (
    <div
      className="section"
      style={{
        textAlign: "center",
        width: `calc(100vw - 260px)`,
        margin: "auto",
      }}
    >
      <h1 className="error">404</h1>
      <div className="page">
        Ooops!!! The page you are looking for is not found
      </div>
      {/* <a className="back-home" href="#!">
        Back to home
      </a> */}
    </div>
  );
}

export default PageNotFound;

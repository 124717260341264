import React from "react";
import "./style.css";
import logo from "../../images/inluvWhite.png";
import { Box } from "@mui/material";

function TermsAndCond() {
  return (
    <Box component="container" sx={{ p: 0 }}>
      {/* <!-- ======= Top-nav-Header ======= --> */}
      <header id="header" className="fixed-top">
        <div className="container header d-flex flex-row justify-content-between align-items-center">
          <img src={logo} alt="inluvLogo" />
          <p style={{ paddingInlineEnd: "20px" }}>Privacy Policy</p>
        </div>
      </header>
      {/* <!-- End Header --> */}
      <main id="main" style={{ paddingLeft: "25px", paddingRight: "25px" }}>
        <section
          id="breadcrumbs"
          className="breadcrumbs"
          style={{ height: "40px" }}
        >
          <div className="breadcrumb-hero"></div>
        </section>

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="privacypolicy" className="privacypolicy">
          <div className="container">
            <div className="section-title">
              <h1>Privacy Policy for InLuv Match Making App</h1>
              <p>
                Last updated: <span>August 18, 2023</span>
              </p>
            </div>

            <div className="privacypolicy_content">
              <p>
                This Privacy Policy outlines the policies and procedures
                governing the collection, use, and disclosure of user
                information when utilizing the InLuv Match Matching App. It also
                elucidates user privacy rights and the legal safeguards in
                place.
              </p>
              <p>
                By using the InLuv Match Matching App, you consent to the
                collection and use of information as described in this Privacy
                Policy.
              </p>
              <h1 className="mt-4">Interpretation and Definitions</h1>
              <h2 className="mt-3">Interpretation</h2>
              <p>
                Words with initial capitalization have defined meanings under
                specific conditions. These definitions remain consistent whether
                in singular or plural form.
              </p>

              <h3 className="mt-3">Definitions</h3>

              <p>
                For the purposes of this Privacy Policy:
                <ul>
                  <li>
                    <strong>Account </strong>A unique account created for
                    accessing the InLuv Match Matching App.
                  </li>
                  <li>
                    <strong>Affiliate </strong>An entity controlling, controlled
                    by, or under common control with a party, where "control"
                    denotes ownership of 50% or more of shares, equity interest,
                    or other securities entitled to vote.
                  </li>
                  <li>
                    <strong>Application </strong>Refers to the InLuv Match
                    Matching App.
                  </li>
                  <li>
                    <strong>Company </strong>Referred to as "the Company," "We,"
                    "Us," or "Our," it designates
                  </li>
                  <li>
                    <strong>Country </strong>Refers to the United States of
                    America.
                  </li>
                  <li>
                    <strong>Device </strong>Encompasses any tool capable of
                    accessing the InLuv Match Matching App, such as a computer,
                    cellphone, or digital tablet.
                  </li>
                  <li>
                    <strong>Personal Data </strong>Encompasses information
                    relating to an identified or identifiable individual.
                  </li>
                  <li>
                    <strong>Service </strong>Denotes the InLuv Match Matching
                    App.
                  </li>
                  <li>
                    <strong>Service Provider </strong>Encompasses any individual
                    or legal entity processing data on behalf of the Company.
                    This includes third-party entities or individuals employed
                    by the Company to facilitate or analyze Service-related
                    activities.
                  </li>
                </ul>
              </p>

              <h1 className="mt-4">Collecting and Using Your Personal Data</h1>
              <h2 className="mt-3">Types of Data Collected</h2>
              <h3 className="mt-3">Personal Data</h3>
              <p>
                While using the InLuv Match Matching App, we may request
                personally identifiable information for contact and
                identification purposes. This may include:
              </p>
              <ul>
                <li>Email address</li>
                <li>First and last names</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/postal code, city</li>
                <li>Usage Data</li>
              </ul>

              <h3 className="mt-3">Usage Data</h3>
              <p>
                Usage Data is collected automatically during app use, it may
                include device IP address, browser type and version, visited
                pages, time and date of visits, time spent on pages, unique
                device identifiers, and other diagnostic data.
              </p>
              <p>
                Information Collected while Using the Application: With user
                consent, the app may collect photo library to provide features
                and improve user experience.
              </p>

              <h1 className="mt-4">Use of Your Personal Data</h1>
              <p>
                The Company may use Personal Data for the following purposes:
              </p>
              <ul>
                <li>To provide and maintain the Service.</li>
                <li>To manage user Accounts.</li>
                <li>
                  For contract performance and purchase-related communications.
                </li>
                <li>
                  To contact users for updates or informative communications.
                </li>
                <li>
                  To provide news, special offers, and general information about
                  Events
                </li>
                <li>To manage user requests.</li>
                <li>
                  For data analysis, identifying usage trends, and improving the
                  Service.
                </li>
              </ul>

              <h1 className="mt-4">Sharing Your Personal Data</h1>
              <p>
                The Company may share personal information in certain
                situations:
              </p>
              <ul>
                <li>
                  With Service Providers for monitoring and analyzing Service
                  usage.
                </li>
                <li>
                  For business transfers or mergers with entities that comply
                  with this Privacy Policy.
                </li>
                <li>
                  With Affiliates, ensuring they adhere to this Privacy Policy.
                </li>
                <li>
                  With business partners to offer products, services, or
                  promotions.
                </li>
                <li>
                  With other users if personal information is shared publicly.
                </li>
                <li>With user consent for specific purposes.</li>
              </ul>

              <h2 className="mt-3">Retention of Your Personal Data</h2>
              <p>
                The Company retains Personal Data only as long as necessary for
                the outlined purposes. Usage Data is retained for internal
                analysis. Deletion requests can be submitted.
              </p>

              <h2 className="mt-3">Transfer of Your Personal Data</h2>
              <p>
                Personal Data may be processed and stored outside the user's
                jurisdiction. The Company ensures secure treatment of data.
              </p>

              <h2 className="mt-3">Security of Your Personal Data</h2>
              <p>
                While the Company employs commercially acceptable security
                measures, no online transmission or electronic storage method is
                100% secure.
              </p>

              <h2 className="mt-3">Children's Privacy</h2>
              <p>
                The InLuv Match Matching App does not target individuals under
                the age of 22. If aware of a child providing Personal Data,
                contact the Company.
              </p>

              <h2 className="mt-3">Links to Other Websites</h2>

              <p>
                The InLuv Match Matching App may contain links to external
                websites. The Company is not responsible for their content or
                privacy policies.
              </p>

              <h2 className="mt-3">Changes to this Privacy Policy</h2>
              <p>
                This Privacy Policy may be updated. Users will be notified of
                changes via email or a prominent notice on the app.
              </p>

              <h2 className="mt-3">Contact Us</h2>
              <p>For inquiries about this Privacy Policy, contact us:</p>

              <ul>
                <li>
                  <strong>By email: </strong>info@inluv.io
                </li>
                <li>
                  <strong>By Visiting: </strong>
                  <span>
                    <a className="text-decoration-none" href="https://inluv.io">
                      https://inluv.io
                    </a>
                  </span>
                </li>
              </ul>

              <p className=" note" style={{ padding: "10px 40px" }}>
                <strong>Note:</strong> This sample privacy policy is a general
                template and may need customization to fit specific legal and
                operational requirements.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- End policy Section --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <footer id="footer" style={{ padding: "10px" }}>
        <div className="container text-center">
          &copy; Copyright{" "}
          <strong>
            <span>
              <a href="" className="text-decoration-none">
                Inluv Prt. Ltd.{" "}
              </a>
            </span>
          </strong>
          All Rights Reserved
        </div>
      </footer>
    </Box>
  );
}

export default TermsAndCond;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import TopNav from "../components/TopNav";
import Box from "@mui/material/Box";
import SideBar from "./SideBar";

const drawerWidth = 220;

function DashboardLayout() {
  const [openSideNav, setOpenSideNav] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");

  useEffect(() => {
    const isOpen = localStorage.getItem("openStatus");
    if (isOpen) {
      setOpenSideNav(isOpen === "true" ? true : false);
    }
  }, []);

  return (
    <>
      <Box height={66} />
      <Box sx={{ display: "flex" }}>
        {/* <SideNav
          open={openSideNav}
          setOpen={setOpenSideNav}
          drawerWidth={drawerWidth}
          setSelectedMenu={setSelectedMenu}
        /> */}
        <SideBar
          open={openSideNav}
          setOpen={setOpenSideNav}
          drawerWidth={drawerWidth}
          setSelectedMenu={setSelectedMenu}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TopNav
            drawerWidth={drawerWidth}
            open={openSideNav}
            setOpen={setOpenSideNav}
            selectedMenu={selectedMenu}
          />
          <Outlet
            context={[openSideNav, drawerWidth, selectedUser, setSelectedUser]}
          />
        </Box>
      </Box>
    </>
  );
}

export default DashboardLayout;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Chart({ dashboardData }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Subscribers Growth",
      },
    },
  };

  let months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  let labels = dashboardData.monthWiseData?.map(
    (item) => `${months[item.month]}-${item.year}`
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Subscribers",
        data: dashboardData.monthWiseData?.map((item) => item.count),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

export default Chart;

// const chartData = [
//   {
//     count: 54,
//     year: 2023,
//     month: 9,
//   },
//   {
//     count: 11,
//     year: 2023,
//     month: 10,
//   },
// ];

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

import React from "react";
import {
  Modal,
  Box,
  Typography,
  Fade,
  Divider,
  CardMedia,
  Button,
  Link,
  Grid,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  height: 540,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EventDetailsModal(props) {
  const {
    modalOpen,
    setModalType,
    selectedEvent,
    handleOpenAddEventModal,
    handleCloseEventDetailModal,
  } = props;

  const dateTime = new Date(selectedEvent?.date);
  const details = selectedEvent?.details?.filter((e) => e);
  const whyAttend = selectedEvent?.whyAttend?.filter((e) => e);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseEventDetailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <CardMedia
              sx={{ height: 280 }}
              image={selectedEvent?.banner?.Location}
              title="event banner"
            />
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              color="var(--primaryColor)"
              sx={{ fontWeight: 600, my: 1 }}
            >
              {selectedEvent?.name}
            </Typography>
            <Box color="text.secondary" sx={{ display: "flex" }}>
              <Box sx={{ display: "flex" }}>
                <CalendarMonthIcon />
                <Typography variant="p" component="h4" sx={{ ml: 1, pt: 0.2 }}>
                  {dateTime.toLocaleDateString()}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", ml: 3 }}>
                <AccessAlarmIcon />
                <Typography variant="p" component="h4" sx={{ ml: 1, pt: 0.2 }}>
                  {dateTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                marginTop: 2,
              }}
            />
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mt: 2, fontWeight: 600 }}
            >
              {selectedEvent?.address}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mt: 2, fontWeight: 600 }}
            >
              Location link:{" "}
              <Link
                href={selectedEvent?.addressLink}
                target="_blank"
                variant="body2"
              >
                {selectedEvent?.addressLink}
              </Link>
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ mt: 2 }}>
              {selectedEvent?.description}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mt: 2, fontWeight: 600 }}
            >
              Registration Link:{" "}
              <Link
                href={selectedEvent?.registerLink}
                target="_blank"
                variant="body2"
              >
                {selectedEvent?.registerLink}
              </Link>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ mt: 2, fontWeight: 600 }}
              >
                Who can access:
              </Typography>
              <Box sx={{ mt: 2, mx: 2 }}>
                {selectedEvent?.plans?.map(
                  (plan, i) =>
                    `${plan.name}${
                      i !== selectedEvent?.plans.length - 1 ? ", " : ""
                    }`
                )}
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ mt: 2, fontWeight: 600 }}
              >
                {details?.length !== 0 ? "Details:" : ""}
              </Typography>
              <Box sx={{ mt: 2, mx: 2 }}>
                {details?.map((item, i) => (
                  <Grid sx={{ display: "flex" }} key={i}>
                    <KeyboardDoubleArrowRightIcon />
                    <Typography>{item}</Typography>
                  </Grid>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ mt: 2, fontWeight: 600 }}
              >
                {whyAttend?.length !== 0 ? "Why Attened:" : ""}
              </Typography>
              <Box sx={{ mt: 2, mx: 2 }}>
                {whyAttend?.map((item, i) => (
                  <Grid sx={{ display: "flex" }} key={i}>
                    <KeyboardDoubleArrowRightIcon />
                    <Typography>{item}</Typography>
                  </Grid>
                ))}
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "var(--primaryColor)", mt: 2, mx: 1 }}
                onClick={() => {
                  setModalType("edit");
                  handleOpenAddEventModal();
                }}
              >
                Edit Event
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default EventDetailsModal;

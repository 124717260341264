import { Box, Card, Skeleton } from "@mui/material";
import React from "react";

function RecentSubscriberSkeleton() {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        // flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1.5,
        mt: 1.5,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" height={30} width={200} sx={{ ml: 2 }} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Skeleton width={20} height={30} sx={{ mr: 1 }} />
        <Skeleton width={90} height={30} />
      </Box>
    </Card>
  );
}

export default RecentSubscriberSkeleton;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Divider from "@mui/material/Divider";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  maxHeight: "75vh",
};
// const currencies = [
//   {
//     value: "USD",
//     label: "$",
//   },
//   {
//     value: "EUR",
//     label: "€",
//   },
//   {
//     value: "BTC",
//     label: "฿",
//   },
//   {
//     value: "JPY",
//     label: "¥",
//   },
// ];

function PlanModal(props) {
  const {
    modalOpen,
    handleClose,
    getPlans,
    setModalType,
    modalType,
    selectedPlan,
  } = props;

  const [name, setName] = useState("");
  const [variants, setVariants] = useState([{ duration: "", price: "" }]);
  const [features, setFeatures] = useState([""]);

  const user = useSelector((state) => state.user);

  const handleAdd = (type) => {
    if (type === "feature") {
      setFeatures([...features, ""]);
    } else {
      setVariants([...variants, { duration: "", price: "" }]);
    }
  };

  const handleRemove = (index, type) => {
    if (type === "feature") {
      let tempFeatures = [...features];
      tempFeatures.splice(index, 1);
      setFeatures(tempFeatures);
    } else {
      let tempVariants = [...variants];
      tempVariants[index].isDeleted = true;
      setVariants(tempVariants);
    }
  };

  const handleChange = (value, i, type) => {
    if (type === "price" || type === "duration") {
      let tempVariants = [...variants];
      tempVariants[i][type] = value;
      if (modalType === "edit") {
        tempVariants[i].isChanged = true;
      }
      setVariants(tempVariants);
    }

    if (type === "feature") {
      let tempFeatures = [...features];
      tempFeatures[i] = value;
      setFeatures(tempFeatures);
    }
  };

  const validateForm = () => {
    let error = false;
    if (name.trim() === "") {
      alert("Please enter the plan name");
      error = true;
    } else if (
      variants.some((item) => item.price === "" || item.duration === "")
    ) {
      alert("Variants can't be empty");
      error = true;
    } else if (features.length === 0) {
      alert("Please enter atleast one feature");
      error = true;
    } else if (features[0].trim() === "") {
      alert("Feature can't be empty");
      error = true;
    } else if (features.some((str) => str === "")) {
      alert("Feature can't be empty");
      let tempFeatures = features.filter((str) => str !== "");
      error = true;
      setFeatures(tempFeatures);
    }
    return error;
  };

  const handleSubmit = async () => {
    try {
      const error = validateForm();
      if (error) {
        return;
      }
      const plan = await axiosCall(
        modalType === "add" ? "POST" : "PUT",
        modalType === "add" ? "plans/v1" : `plans/v1/${selectedPlan._id}`,
        {
          name,
          variants,
          features,
        },
        null,
        user.token
      );

      if (plan.isSuccess) {
        handleClose();
        getPlans();
        setModalType(modalType === "add" ? "edit" : "add");
      } else {
        console.log(plan.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (modalType === "edit") {
      setName(selectedPlan?.name);
      setFeatures(selectedPlan?.features);
      setVariants(JSON.parse(JSON.stringify(selectedPlan?.variants)));
    } else {
      setName("");
      setFeatures([""]);
      setVariants([{ duration: "", price: "" }]);
    }
  }, [modalType, selectedPlan]);

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                marginBottom: 2,
              }}
            >
              {modalType === "add" ? "Add New" : "Edit"} Plan
            </Typography>
            <Divider />
            <Box component="form" noValidate method="get" sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Plan Name"
                name="name"
                size="small"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Typography sx={{ mt: 1 }}>Plan variants</Typography>
              {variants.map(
                (variant, i) =>
                  !variants[i].isDeleted && (
                    <Box key={i} sx={{ display: "flex", position: "relative" }}>
                      <TextField
                        margin="dense"
                        required
                        fullWidth
                        id="price"
                        type="number"
                        label="Price"
                        name="price"
                        size="small"
                        value={variants[i].price}
                        onChange={(e) =>
                          handleChange(e.target.value, i, "price")
                        }
                      />
                      <TextField
                        margin="dense"
                        required
                        fullWidth
                        id="duration"
                        type="number"
                        label="Duration"
                        name="duration"
                        sx={{ ml: 1 }}
                        size="small"
                        value={variants[i].duration}
                        onChange={(e) =>
                          handleChange(e.target.value, i, "duration")
                        }
                      />
                      <Box
                        sx={{
                          border: "1px solid gray",
                          mb: "10px",
                          mt: "8px",
                          borderRadius: "0 5px 5px 0",
                        }}
                      >
                        <Tooltip
                          title="Remove"
                          onClick={() => handleRemove(i, "variant")}
                        >
                          <IconButton sx={{ height: 38, color: "#f00" }}>
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  size="small"
                  sx={{
                    fontSize: "12px",
                    color: "#0055ff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAdd("variant")}
                >
                  + Add New Variant
                </Typography>
              </Box>
              <Typography sx={{ mt: 1 }}>Features</Typography>
              {features.map((feature, i) => (
                <Box key={i} sx={{ display: "flex" }}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    margin="dense"
                    name="feature"
                    label={`Feature ${i + 1}`}
                    value={features[i]}
                    onChange={(e) => handleChange(e.target.value, i, "feature")}
                  />
                  <Box
                    sx={{
                      border: "1px solid gray",
                      mb: "10px",
                      mt: "8px",
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    <Tooltip
                      title="Remove"
                      onClick={() => handleRemove(i, "feature")}
                    >
                      <IconButton sx={{ height: 38, color: "#f00" }}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  size="small"
                  sx={{
                    fontSize: "12px",
                    color: "#0055ff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAdd("feature")}
                >
                  + Add New Feature
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#ccc", my: 2, mx: 1 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "var(--primaryColor)", my: 2, mx: 1 }}
                onClick={handleSubmit}
              >
                {modalType === "add" ? "Add" : "Update"} Plan
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default PlanModal;

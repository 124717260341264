import React from "react";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

function PlansCard({ plan, setModalType, handleOpen, setSelectedPlan }) {
  return (
    <>
      <Paper
        sx={{
          width: "20rem",
          height: "100%",
          flexShrink: 0,
          borderRadius: "10px",
          boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
          pt: 5,
          pb: 4,
          paddingX: 4,
          mb: 3,
          mr: 3,
        }}
      >
        <Typography
          variant="h4"
          color="#000"
          component="h4"
          sx={{
            display: { xs: "block", sm: "block" },
            fontWeight: "600",
            fontSize: "2.25rem",
            mb: 2,
            letterSpacing: "-0.02081rem",
            color: "var(--primaryColor)",
          }}
        >
          {plan.name}
        </Typography>
        <Grid container item spacing={2} sx={{ textAlign: "center" }}>
          {plan.variants?.map((data, index) => (
            <Grid item xs={4} key={index}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant=""
                  color="var(--secondaryColor)"
                  component="p"
                  sx={{
                    fontWeight: "700",
                  }}
                >
                  {data.duration ? `${data.duration} mos` : "Unlimited"}
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  sx={{
                    fontWeight: "700",
                    fontSize: "1.875rem",
                  }}
                >
                  ${data.price}
                </Typography>
                <Typography color="#777">/month</Typography>
              </Box>

              <Divider orientation="vertical" flexItem />
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ marginTop: 4 }} />
        <Box
          sx={{
            marginTop: 3,
            maxWidth: "20rem",
            height: "12rem",
            overflow: "auto",
          }}
          className="planDetails"
        >
          {plan.features?.map((item, i) => (
            <Grid sx={{ display: "flex" }} key={i}>
              <DoneIcon fontSize="small" sx={{ marginRight: 1 }} />
              <Typography color="#777">{item}</Typography>
            </Grid>
          ))}
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "var(--primaryColor)",
            width: "100%",
            marginTop: 4,
          }}
          onClick={() => {
            setSelectedPlan({ ...plan });
            setModalType("edit");
            handleOpen();
          }}
        >
          Edit Plan
        </Button>
      </Paper>
    </>
  );
}

export default PlansCard;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@mui/material";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LinearProgress from "@mui/material/LinearProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 700,
  height: 500,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const modes = [
  {
    value: "In-Person",
    label: "In-Person",
  },
  {
    value: "Virtual",
    label: "Virtual",
  },
];

function AddEventModal(props) {
  const {
    modalOpen,
    handleCloseAddEventModal,
    getEvents,
    modalType,
    selectedEvent,
    handleCloseEventDetailModal,
    setPageLoader,
  } = props;

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [mode, setMode] = useState("");
  const [address, setAddress] = useState("");
  const [addressLink, setAddressLink] = useState("");
  const [registerLink, setRegisterLink] = useState("");
  const [plans, setPlans] = useState([]);
  const [savedPlans, setSavedPlans] = useState([]);
  const [description, setDescription] = useState("");
  const [details, setDetails] = useState([""]);
  const [whyAttend, setWhyAttend] = useState([""]);
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const formData = new FormData();

  const user = useSelector((state) => state.user);

  const handleUpload = async (e) => {
    setLoading(true);
    formData.append("type", "banner");
    formData.append("image", e.target.files[0]);

    const plansRes = await axiosCall(
      "POST",
      "master/v1/image",
      formData,
      null,
      user.token
    );

    if (plansRes.isSuccess) {
      setBanner(plansRes.data?.data);
      setLoading(false);
    } else {
      alert(plansRes.error?.message);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const getPlans = async () => {
    try {
      const plansRes = await axiosCall(
        "GET",
        "plans/v1/",
        null,
        null,
        user.token
      );

      if (plansRes.isSuccess) {
        setSavedPlans(plansRes.data?.data);
      } else {
        alert(plansRes.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdd = (type) => {
    if (type === "detail") {
      setDetails([...details, ""]);
    } else {
      setWhyAttend([...whyAttend, ""]);
    }
  };

  const handleRemove = (index, type) => {
    if (type === "detail") {
      let newDetails = [...details];
      newDetails.splice(index, 1);
      setDetails(newDetails);
    } else {
      let newWhyAttend = [...whyAttend];
      newWhyAttend.splice(index, 1);
      setWhyAttend(newWhyAttend);
    }
  };

  const handleChange = (value, i, type) => {
    if (type === "detail") {
      let tempDetails = [...details];
      tempDetails[i] = value;
      setDetails(tempDetails);
    }
    if (type === "whyAttend") {
      let tempWhyAttend = [...whyAttend];
      tempWhyAttend[i] = value;
      setWhyAttend(tempWhyAttend);
    }
  };

  const validateForm = () => {
    if (name.trim() === "") {
      alert("Please enter the event name");
      return true;
    } else if (date === "") {
      alert("Please enter the date");
      return true;
    } else if (mode === "") {
      alert("Please select a mode");
      return true;
    } else if (address.trim() === "") {
      alert("Address field can't be blank");
      return true;
    } else if (registerLink.trim() === "") {
      alert("Please enter the registration link");
      return true;
    } else if (plans.length === 0) {
      alert("Please select minimum one plan");
      return true;
    } else if (description.trim() === "") {
      alert("Please enter the description");
      return true;
    } else if (details.some((str) => str === "")) {
      alert("Details can't be empty");
      let tempDetails = details.filter((str) => str !== "");
      setDetails(tempDetails);
      return true;
    } else if (whyAttend.some((str) => str === "")) {
      alert("Why attend can't be empty");
      let tempWhyAttend = whyAttend.filter((str) => str !== "");
      setWhyAttend(tempWhyAttend);
      return true;
    } else if (!banner) {
      alert("Please select one image file for the banner");
      return true;
    }
  };

  const handleSubmit = async () => {
    if (!loading) {
      try {
        const error = validateForm();
        if (error) {
          return;
        }
        setDisableButton(true);
        const event = await axiosCall(
          modalType === "add" ? "POST" : "PUT",
          modalType === "add" ? "events/v1" : `events/v1/${selectedEvent?._id}`,
          {
            name,
            date,
            mode,
            address,
            addressLink,
            registerLink,
            plans,
            description,
            details,
            whyAttend,
            banner,
          },
          null,
          user.token
        );
        if (event.isSuccess) {
          handleCloseAddEventModal();
          handleCloseEventDetailModal();
          setDisableButton(false);
          getEvents();
        } else {
          console.log(event.error?.message);
          setDisableButton(false);
        }
      } catch (error) {
        setDisableButton(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (modalType === "edit") {
      setName(selectedEvent?.name);
      setDate(handleDateTimeFormat(selectedEvent?.date));
      setMode(selectedEvent?.mode);
      setAddress(selectedEvent?.address);
      setAddressLink(selectedEvent?.addressLink);
      setRegisterLink(selectedEvent?.registerLink);
      setPlans(selectedEvent?.plans.map((item) => item._id));
      setDescription(selectedEvent?.description);
      setDetails(selectedEvent?.details);
      setWhyAttend(selectedEvent?.whyAttend);
      setBanner(selectedEvent?.banner);
      setShowPreview(true);
    } else {
      setName("");
      setDate("");
      setMode("");
      setAddress("");
      setAddressLink("");
      setRegisterLink("");
      setDescription("");
      setPlans([]);
      setDetails([""]);
      setWhyAttend([""]);
      setBanner("");
      setShowPreview(false);
    }
  }, [modalType, selectedEvent]);

  const handleDateTimeFormat = (inputDateString) => {
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hour = String(inputDate.getUTCHours()).padStart(2, "0");
    const minute = String(inputDate.getUTCMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hour}:${minute}`;
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseAddEventModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={style}>
            <Box sx={{ bgcolor: "#fff", width: "100%" }}>
              <Typography
                variant="p"
                component="h2"
                sx={{
                  marginBottom: 2,
                  position: "sticky",
                }}
              >
                {modalType === "add" ? "Add New" : "Edit"} Event
              </Typography>
            </Box>
            <Box component="form" noValidate method="get" sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Event Name"
                name="name"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Box sx={{ display: "flex" }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  // label="Date"
                  name="date"
                  size="small"
                  type={"datetime-local"}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  sx={{ mr: 1 }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Mode"
                  name="mode"
                  select
                  size="small"
                  sx={{ ml: 1 }}
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                >
                  {modes?.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <TextField
                margin="normal"
                required
                fullWidth
                multiline
                label="Address"
                name="address"
                size="small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <TextField
                margin="normal"
                fullWidth
                label="Address Link"
                name="addresslink"
                size="small"
                value={addressLink}
                onChange={(e) => setAddressLink(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Register Link"
                name="registerlink"
                size="small"
                value={registerLink}
                onChange={(e) => setRegisterLink(e.target.value)}
              />
              <TextField
                margin="normal"
                SelectProps={{ multiple: true }}
                required
                fullWidth
                label="Plans"
                name="plans"
                size="small"
                select
                value={plans}
                onChange={(e) => setPlans(e.target.value)}
              >
                {savedPlans?.map((option, index) => (
                  <MenuItem key={index} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Description"
                name="description"
                size="small"
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {details?.map((detail, i) => (
                <Box key={i} sx={{ display: "flex" }}>
                  <TextField
                    sx={{ width: "94%" }}
                    id="price"
                    size="small"
                    name="details"
                    margin="normal"
                    label={`Details ${i + 1}`}
                    value={details[i]}
                    onChange={(e) => handleChange(e.target.value, i, "detail")}
                  />
                  <Box
                    sx={{
                      border: "1px solid gray",
                      mb: "10px",
                      mt: "16px",
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    <Tooltip
                      title="Remove"
                      onClick={() => handleRemove(i, "detail")}
                    >
                      <IconButton sx={{ height: 38, color: "#f00" }}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  size="small"
                  sx={{ fontSize: "12px", color: "#0055ff", cursor: "pointer" }}
                  onClick={() => handleAdd("detail")}
                >
                  + Add New Details
                </Typography>
              </Box>
              {whyAttend?.map((whyattend, i) => (
                <Box key={i} sx={{ display: "flex" }}>
                  <TextField
                    margin="normal"
                    sx={{ width: "94%" }}
                    label={`Why Attend ${i + 1}`}
                    name="whyattend"
                    size="small"
                    value={whyAttend[i]}
                    onChange={(e) =>
                      handleChange(e.target.value, i, "whyAttend")
                    }
                  />
                  <Box
                    sx={{
                      border: "1px solid gray",
                      mb: "10px",
                      mt: "16px",
                      borderRadius: "0 5px 5px 0",
                    }}
                  >
                    <Tooltip
                      title="Remove"
                      onClick={() => handleRemove(i, "whyAttend")}
                    >
                      <IconButton sx={{ height: 38, color: "#f00" }}>
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Typography
                  size="small"
                  sx={{ fontSize: "12px", color: "#0055ff", cursor: "pointer" }}
                  onClick={() => handleAdd("whyAttend")}
                >
                  + Add New Reason
                </Typography>
              </Box>
            </Box>
            {showPreview ? (
              <Box sx={{ width: "100%" }} onClick={() => setShowPreview(false)}>
                <img
                  className="banner-image"
                  src={banner.Location}
                  alt="banner"
                />
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="banner"
                  size="small"
                  type="file"
                  onChange={(e) => handleUpload(e)}
                  sx={{ mr: 1 }}
                  inputProps={{ accept: "image/*" }}
                />
                {loading ? <LinearProgress /> : ""}
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#ccc", mt: 2, mx: 1 }}
                onClick={handleCloseAddEventModal}
              >
                Cancel
              </Button>
              {disableButton ? (
                <Button
                  disabled
                  variant="contained"
                  sx={{ backgroundColor: "var(--primaryColor)", mt: 2, ml: 1 }}
                >
                  {modalType === "add" ? "Add" : "Update"} Event
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "var(--primaryColor)", mt: 2, ml: 1 }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {modalType === "add" ? "Add" : "Update"} Event
                </Button>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default AddEventModal;

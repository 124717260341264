import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { Avatar, Button, ListItemText, Snackbar } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import { useEffect } from "react";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import UserProfileDrawer from "../components/UserProfileDrawer";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const headCells = [
  {
    id: "profile",
    label: "Profile",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "mail",
    label: "Email",
  },
  {
    id: "joiningDate",
    label: "Joining date",
  },
  {
    id: "plan",
    label: "Plan",
  },
  {
    id: "matches",
    label: "Matches",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#F0F9FF" }}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sx={{ fontWeight: 600 }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    selectedUser,
    userName,
    selected,
    setOpenSuccessSnackbar,
  } = props;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const sendProfiles = async () => {
    try {
      const sendProfile = await axiosCall(
        "POST",
        "admin/v1/recommendProfiles",
        { userId: selectedUser, profiles: selected },
        null,
        user.token
      );
      if (sendProfile.isSuccess) {
        setOpenSuccessSnackbar(true);
        setTimeout(() => {
          navigate("/users");
        }, 4000);
      } else {
        console.log(sendProfile.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ flex: "1 1 100%", fontWeight: "700" }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                Find matches for {userName}
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Select the users from the below list and match them with this
                profile
              </Typography>
            </React.Fragment>
          }
        />
      )}

      {numSelected > 0 ? (
        <IconButton>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "var(--primaryColor)",
              textTransform: "none",
            }}
            onClick={() => {
              sendProfiles();
            }}
          >
            <SendIcon sx={{ mr: 1 }} />
            {numSelected == 1 ? "Send Profile" : "Send Profiles"}
          </Button>
        </IconButton>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>{/* <FilterListIcon /> */}</IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function Matches() {
  const { state } = useLocation();
  const [open, drawerWidth, selectedUser] = useOutletContext();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allUsers, setAllUsers] = useState([]);
  const [isUserDrawerOpen, setIsUserDrawerOpen] = React.useState(false);
  const [userData, setUserData] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [drawerUserId, setDrawerUserId] = useState();
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);

  const user = useSelector((state) => state.user);

  const handleUserDetails = async (userId) => {
    try {
      setLoading(true);
      const userDetails = await axiosCall(
        "GET",
        "admin/v1/user",
        null,
        {
          userId: userId,
        },
        user.token
      );
      if (userDetails.data.isSuccess) {
        setUserData(userDetails?.data?.data);
        setLoading(false);
      } else {
        console.log(userDetails.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allUsers?.users?.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    document.title = "Inluv Admin | Matches";
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allUsers?.userCount) : 0;

  useEffect(() => {
    getUsers();
  }, [page]);

  const getUsers = async (sortingOrder = 1) => {
    try {
      const userRes = await axiosCall(
        "GET",
        "admin/v1/suggestions",
        null,
        { offset: page * 10, sortingOrder, userId: selectedUser },
        user.token
      );
      if (userRes.data.isSuccess) {
        setAllUsers(userRes.data?.data);
      } else {
        console.log(userRes.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        p: 1,
        position: "relative",
        display: "flex",
        width: "98.3vw",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedUser={selectedUser}
          userName={state.name}
          selected={selected}
          setOpenSuccessSnackbar={setOpenSuccessSnackbar}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={allUsers?.users?.length}
            />
            <TableBody>
              {allUsers?.users?.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                const date = new Date(row.createdAt);

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row._id)}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ pl: 2 }}
                    >
                      <Box
                        onClick={() => {
                          setIsUserDrawerOpen(true);
                          setDrawerUserId(row._id);
                          handleUserDetails(row._id);
                        }}
                      >
                        {row.avatar ? (
                          row.avatar
                        ) : (
                          <Avatar
                            alt={row.fullName}
                            src="/"
                            sx={{
                              border: "3px solid",
                              borderColor: `${
                                row.isActive ? "#4BB543" : "#FA374E"
                              }`,
                            }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.fullName}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "250px",
                        paddingRight: "0",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell>{date.toLocaleDateString()}</TableCell>
                    <TableCell>
                      {row.plan[0] ? row.plan[0].name : "N/A"}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "160px",
                        paddingRight: "0",
                      }}
                    >
                      {row.matches}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={allUsers?.userCount || 10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <UserProfileDrawer
        isUserDrawerOpen={isUserDrawerOpen}
        setIsUserDrawerOpen={setIsUserDrawerOpen}
        userData={userData}
        loading={loading}
        drawerUserId={drawerUserId}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSuccessSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnackbar(false)}
        TransitionComponent={TransitionDown}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => setOpenSuccessSnackbar(false)}
        >
          {selected.length == 1
            ? "1 suggestion sent..."
            : `${selected.length} suggestions sent...`}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}

export default Matches;

import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

function EventCard(props) {
  const { event, handleOpen, selectedEvent, setSelectedEvent } = props;

  const dateTime = new Date(event?.date);
  function truncate(str, maxlength) {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + "…" : str;
  }

  return (
    <Card
      sx={{ width: 370, mr: 4, my: 2, borderRadius: 1.5, cursor: "pointer" }}
      onClick={() => {
        setSelectedEvent(event);
        handleOpen();
      }}
    >
      <Box sx={{ position: "relative" }}>
        <CardMedia
          sx={{ height: 200 }}
          image={event?.banner?.Location}
          title="event banner"
        />
        <Typography
          gutterBottom
          variant="h2"
          component="div"
          sx={{
            position: "absolute",
            color: "#fff",
            fontSize: "16px",
            left: 20,
            bottom: 10,
          }}
        >
          {truncate(event?.name, 80)}
        </Typography>
      </Box>
      <CardContent>
        <Typography variant="body2" color="text.primary" sx={{ pl: 0.5 }}>
          {event?.address}
        </Typography>
      </CardContent>

      <Box color="text.secondary" sx={{ display: "flex", pl: 2, pb: 2 }}>
        <Box sx={{ display: "flex" }}>
          <CalendarMonthIcon />
          <Typography sx={{ ml: 1, fontSize: "14px", pt: 0.5 }}>
            {dateTime.toLocaleDateString()}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", ml: 3 }}>
          <AccessAlarmIcon />
          <Typography sx={{ ml: 1, fontSize: "14px", pt: 0.5 }}>
            {dateTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export default EventCard;

import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "../components/Chart";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import UpcomingEventCardSkeleton from "../components/skeletons/UpcomingEventCardSkeleton";
import RecentSubscriberSkeleton from "../components/skeletons/RecentSubscriberSkeleton";

const cardStyle = {
  mt: 2,
  mr: 2,
  px: 1.5,
  height: 90,
  display: "flex",
  alignItems: "center",
  minWidth: 150,
};

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await axiosCall(
        "GET",
        "admin/v1/dashboard",
        null,
        null,
        user.token
      );
      if (data.data?.isSuccess) {
        console.log(data.data);
        setDashboardData(data.data?.data);
        setLoading(false);
      } else {
        console.log(data.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    document.title = "Inluv Admin | Home";
  }, []);

  // console.log(dashboardData?.analytics[0]?.genderCount?.map((e) => e));

  // const married = dashboardData?.marriedOrNot?.map((e, index) => {
  //   if (e.married === "Yes") {
  //     return e.count;
  //   }
  // });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        // p: 1,
        width: "100vw",
      }}
    >
      <Grid container sx={{ p: 1 }}>
        <Grid xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{
              py: 1.5,
              padding: 2,
            }}
          >
            <Typography
              variant="h5"
              color="var(--primaryColor)"
              component="h6"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                pl: 0.2,
              }}
            >
              Analytics
            </Typography>
            <Box sx={{ display: "flex", mt: 0, flexWrap: "wrap" }}>
              <Card variant="outlined" sx={cardStyle}>
                <Box
                  sx={{ px: 2, py: 1.6, borderRadius: 1, bgcolor: "#0890fe" }}
                >
                  <PeopleAltIcon sx={{ fontSize: 36, color: "#fff" }} />
                </Box>
                <Box sx={{ ml: 1.2, p: 0 }}>
                  <Typography
                    sx={{ m: 0, fontSize: 16, fontWeight: 600 }}
                    color="text.primary"
                  >
                    Total User
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 700 }}
                  >
                    {/* {dashboardData?.analytics[0]?.totalCount} */}
                    80
                  </Typography>
                </Box>
              </Card>
              <Card variant="outlined" sx={cardStyle}>
                <Box
                  sx={{
                    px: 2,
                    py: 1.6,
                    borderRadius: 1,
                    bgcolor: "#52d5ba",
                  }}
                >
                  <MaleIcon sx={{ fontSize: 36, color: "#fff" }} />
                </Box>
                <Box sx={{ ml: 1.2, p: 0 }}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 600 }}
                    color="text.primary"
                  >
                    Male
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 700 }}
                  >
                    32
                  </Typography>
                </Box>
              </Card>
              <Card variant="outlined" sx={cardStyle}>
                <Box
                  sx={{ px: 2, py: 1.6, borderRadius: 1, bgcolor: "#ff4267" }}
                >
                  <FemaleIcon sx={{ fontSize: 36, color: "#fff" }} />
                </Box>
                <Box sx={{ ml: 1.2, p: 0 }}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 600 }}
                    color="text.primary"
                  >
                    Female
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 700 }}
                  >
                    15
                  </Typography>
                </Box>
              </Card>
              <Card variant="outlined" sx={cardStyle}>
                <Box
                  sx={{ px: 2, py: 1.6, borderRadius: 1, bgcolor: "#a73ad6" }}
                >
                  <ThumbUpAltIcon sx={{ fontSize: 36, color: "#fff" }} />
                </Box>
                <Box sx={{ ml: 1.2, p: 0 }}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 600 }}
                    color="text.primary"
                  >
                    Married
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 700 }}
                  >
                    15
                  </Typography>
                </Box>
              </Card>
              <Card variant="outlined" sx={cardStyle}>
                <Box
                  sx={{ px: 2, py: 1.6, borderRadius: 1, bgcolor: "#ffaf2a" }}
                >
                  <ThumbDownAltIcon sx={{ fontSize: 36, color: "#fff" }} />
                </Box>
                <Box sx={{ ml: 1.2, p: 0 }}>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 600 }}
                    color="text.primary"
                  >
                    Unmarried
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ fontWeight: 700 }}
                  >
                    15
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Paper>
          <Paper
            elevation={3}
            sx={{
              mt: 1,
              p: 2,
              height: "59vh",
            }}
          >
            {/* <Typography
              variant="h5"
              color="var(--primaryColor)"
              component="h6"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                pl: 0.2,
              }}
            >
              Month Wise Data
            </Typography> */}
            <Chart dashboardData={dashboardData} />
          </Paper>
        </Grid>
        <Grid xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              ml: 1,
              p: 2,
              height: "fitContent",
            }}
          >
            <Typography
              variant="h5"
              color="var(--primaryColor)"
              component="h6"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                pl: 0.2,
              }}
            >
              Recent Subscribers
            </Typography>
            {!loading ? (
              dashboardData?.recentSubscibers?.map((data, index) => (
                <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 1.5,
                    mt: 1.5,
                    background:
                      "linear-gradient(to right top, #F0F9FF, rgba(255, 255, 255, 0.00))",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar alt={data.fullName} src="/" />
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 600, ml: 2 }}
                      color="text.primary"
                    >
                      {data.fullName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PaymentIcon style={{ color: "#0a0" }} />
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 600, ml: 1 }}
                      color="text.secondary"
                    >
                      {data.plan?.name}
                    </Typography>
                  </Box>
                </Card>
              ))
            ) : (
              <Box>
                <RecentSubscriberSkeleton />
                <RecentSubscriberSkeleton />
                <RecentSubscriberSkeleton />
              </Box>
            )}
          </Paper>
          <Paper
            elevation={3}
            sx={{
              ml: 1,
              mt: 1,
              p: 2,
              height: "fitContent",
            }}
          >
            <Typography
              variant="h5"
              color="var(--primaryColor)"
              component="h6"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                pl: 0.2,
              }}
            >
              Upcoming Events
            </Typography>
            {!loading ? (
              dashboardData?.upcomingEvents?.map((data, index) => {
                const dateTime = new Date(data?.date);
                function truncate(str, maxlength) {
                  return str.length > maxlength
                    ? str.slice(0, maxlength - 1) + "…"
                    : str;
                }
                return (
                  <Card
                    variant="outlined"
                    key={index}
                    sx={{
                      display: "flex",
                      p: 1.5,
                      mt: 1,
                      background:
                        "linear-gradient(to right top, #F0F9FF, rgba(255, 255, 255, 0.00))",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ height: 66, width: 66, borderRadius: 1 }}
                      image={data?.banner.Location}
                      alt=""
                    />
                    <Box>
                      <Typography
                        sx={{ fontSize: 16, fontWeight: 600, ml: 2 }}
                        color="text.primary"
                      >
                        {truncate(data?.name, 40)}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 600, ml: 2 }}
                        color="text.secondary"
                      >
                        Date: {dateTime.toLocaleDateString()}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 600, ml: 2 }}
                        color="text.secondary"
                      >
                        Time:{" "}
                        {dateTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </Typography>
                    </Box>
                  </Card>
                );
              })
            ) : (
              <Box>
                <UpcomingEventCardSkeleton />
                <UpcomingEventCardSkeleton />
                <UpcomingEventCardSkeleton />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;

import * as React from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import { axiosCall } from "../utils/axiosCall";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    secondary: {
      main: red[500],
    },
  },
});

function StatusForm(props) {
  const { drawerUserId, setOpenSuccessSnackbar, handleUserDetails, getUsers } =
    props;
  const [selectedOption, setSelectedOption] = React.useState("");
  const user = useSelector((state) => state.user);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const interview = await axiosCall(
        "POST",
        "/admin/v1/interview",
        {
          userId: drawerUserId,
          type: selectedOption,
        },
        null,
        user.token
      );
      if (interview.isSuccess) {
        setOpenSuccessSnackbar(true);
        handleUserDetails(drawerUserId);
        getUsers();
      } else {
        alert(interview.error?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ px: 1.5, py: 3 }}
    >
      <FormControl>
        <Typography
          id="demo-row-radio-buttons-group-label"
          sx={{ color: "#333" }}
        >
          <strong>Interview Status:</strong>
        </Typography>
        <ThemeProvider theme={theme}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <ThemeProvider
              theme={(theme) =>
                createTheme({
                  ...theme,
                  palette: {
                    ...theme.palette,
                    primary: {
                      main: green[500],
                    },
                  },
                })
              }
            >
              <FormControlLabel
                value="Accepted"
                checked={selectedOption === "Accepted"}
                onChange={handleOptionChange}
                control={<Radio sx={{ color: green[500] }} />}
                label="Accept"
                sx={{ color: green[500] }}
              />
            </ThemeProvider>
            <ThemeProvider
              theme={(theme) =>
                createTheme({
                  ...theme,
                  palette: {
                    ...theme.palette,
                    primary: {
                      main: red[500],
                    },
                  },
                })
              }
            >
              <FormControlLabel
                value="Rejected"
                checked={selectedOption === "Rejected"}
                onChange={handleOptionChange}
                control={<Radio sx={{ color: red[500] }} />}
                label="Reject"
                sx={{ color: red[500] }}
              />
            </ThemeProvider>
          </RadioGroup>
        </ThemeProvider>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "var(--primaryColor)",
            float: "right",
            width: "55%",
            mt: 2,
          }}
          type="submit"
        >
          Submit
        </Button>
      </FormControl>
    </Box>
  );
}

export default StatusForm;

import React from "react";
import banner from "../images/bannerImg.png";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

function Banner() {
  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Paper
          elevation={3}
          sx={{
            py: 1.5,
            px: "4rem",
            display: "flex",
            flexDirection: "row",
            minWidth: "0",
          }}
        >
          <img
            src={banner}
            alt=""
            style={{ width: "247px", height: "200px" }}
          />
          <Box sx={{ ml: 6, my: 2.5 }}>
            <Typography
              variant="h6"
              color="#000"
              component="h1"
              sx={{
                display: { xs: "block", sm: "block" },
                fontWeight: "700",
                mb: 2,
              }}
            >
              Welcome to InLuv admin
            </Typography>
            <Typography
              paragraph
              style={{
                textAlign: "justify",
                lineHeight: "27px",
                letterSpacing: ".32px",
              }}
            >
              Welcome to the InLuv Dating App Admin! Here, you'll have the power
              to manage user profiles, monitor real-time activity, and ensure a
              safe and enjoyable experience for our members. From moderating
              content to analyzing user data, this dashboard empowers you to
              keep our dating community thriving and secure.
            </Typography>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default Banner;

import React from "react";
import home from "../images/home.png";
import user from "../images/user.png";
import plan from "../images/plan.png";
import events from "../images/events.png";
import payment from "../images/payment.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

function SideNavMenu(props) {
  const { open, setOpen, setSelectedMenu } = props;
  const navigate = useNavigate();

  const menuItem = [
    {
      id: 1,
      src: home,
      path: "home",
      name: "Dashboard",
    },
    {
      id: 2,
      src: user,
      path: "users",
      name: "Users",
    },
    {
      id: 3,
      src: plan,
      path: "plans",
      name: "Plans",
    },
    {
      id: 4,
      src: events,
      path: "events",
      name: "Events",
    },
    {
      id: 5,
      src: payment,
      path: "payment",
      name: "Payments",
    },
  ];
  return (
    <>
      <List style={{ marginTop: "10rem" }}>
        {menuItem.map((data) => (
          <ListItem key={data.id} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 52,
                px: 3,
              }}
              onClick={() => {
                setSelectedMenu(data.name);
                navigate(`/${data.path}`);
                setOpen(!open);
              }}
            >
              <img
                src={data.src}
                alt=""
                style={{ width: "20px" }}
                sx={{
                  mr: open ? 5 : "auto",
                  justifyContent: "center",
                }}
              />
              <ListItemText primary={data.name} sx={{ ml: 2 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default SideNavMenu;

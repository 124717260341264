import { Box, Divider, Grid, Paper, Skeleton } from "@mui/material";
import React from "react";

function PlansCardSkeleton() {
  return (
    <Paper
      sx={{
        width: "20rem",
        height: "fit-content",
        flexShrink: 0,
        borderRadius: "10px",
        boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)",
        pt: 5,
        pb: 4,
        paddingX: 4,
        mb: 3,
        mr: 3,
      }}
    >
      <Skeleton width="60%" height={40} />
      <Grid container item spacing={2} sx={{ textAlign: "center" }}>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <Skeleton height={140} width={70} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", flexDirection: "column", ml: 1.5 }}>
            <Skeleton height={140} width={70} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Skeleton height={140} width={70} />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2 }} />
      <Box sx={{ mt: 2 }}>
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
        <Skeleton width="100%" height={20} sx={{ mt: 0.4 }} />
      </Box>
      <Skeleton width="100%" height={60} sx={{ mt: 1 }} />
    </Paper>
  );
}

export default PlansCardSkeleton;

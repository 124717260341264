import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KababMenu from "./KebabMenu";
import { useOutletContext } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const headCells = [
  {
    id: "profile",
    label: "Profile",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "mail",
    label: "Email",
  },
  {
    id: "joiningDate",
    label: "Joining date",
  },
  {
    id: "plan",
    label: "Plan",
  },
  {
    id: "interviewResult",
    label: "Interview Result",
  },
  {
    id: "matches",
    label: "Matches",
  },
  {
    id: "findMatch",
    label: "Action",
  },
  {
    id: "menu",
    label: "Menu",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#F0F9FF" }}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sx={{ fontWeight: 600 }}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableToolbar(props) {
  const { searchUser, setSearchUser, setPage } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 3 },
        pr: { xs: 2, sm: 2 },
        py: 1,
      }}
    >
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              sx={{ flex: "1 1 100%", fontWeight: "700" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              All Users
            </Typography>
          </React.Fragment>
        }
        secondary={
          <React.Fragment>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              Check the joined users and get the matches for them
            </Typography>
          </React.Fragment>
        }
      />
      <Paper
        variant="outlined"
        sx={{
          px: 2,
          py: 0.8,
          display: "flex",
          alignItems: "center",
        }}
      >
        <PersonSearchIcon sx={{ ml: 1, color: "gray" }} />
        <InputBase
          sx={{ ml: 1, mt: 0.5, flex: 1 }}
          type="search"
          spellCheck="false"
          placeholder="Search user"
          inputProps={{ "aria-label": "search user" }}
          value={searchUser}
          onChange={(e) => {
            if (!e.target.value) {
              setSearchUser();
            } else {
              setPage(0);
              setSearchUser(e.target.value);
            }
          }}
        />
      </Paper>
    </Toolbar>
  );
}

function DataTable(props) {
  const navigate = useNavigate();
  const {
    users,
    page,
    setPage,
    getUsers,
    setIsUserDrawerOpen,
    searchUser,
    setSearchUser,
    handleUserDetails,
    setDrawerUserId,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [deactivateUserId, setDeactivateUserId] = useState("dsfs");

  // console.log(deactivateUserId);

  const [openSideNav, drawerWidth, selectedUser, setSelectedUser] =
    useOutletContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  return (
    <Box
      sx={{
        p: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Paper elevation={3} sx={{ width: "100%", mb: 2 }}>
        <TableToolbar
          searchUser={searchUser}
          setSearchUser={setSearchUser}
          setPage={setPage}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {users?.users?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                const date = new Date(row.createdAt);

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ pl: 2 }}
                    >
                      <Box
                        onClick={() => {
                          setIsUserDrawerOpen(true);
                          setDrawerUserId(row._id);
                          handleUserDetails(row._id);
                        }}
                      >
                        {row.avatar ? (
                          row.avatar
                        ) : (
                          <Avatar
                            alt={row.fullName}
                            src="/"
                            sx={{
                              border: "3px solid",
                              borderColor: `${
                                row.isActive ? "#4BB543" : "#FA374E"
                              }`,
                            }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      style={{
                        width: "230px",
                        paddingRight: "0",
                      }}
                    >
                      {row.fullName}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "250px",
                        paddingRight: "0",
                      }}
                    >
                      {row.email}
                    </TableCell>
                    <TableCell>{date.toLocaleDateString()}</TableCell>
                    <TableCell>
                      {/* {row.plan[0]?.name
                        ? row.plan[0]?.name === "Free"
                          ? row.plan[0]?.name
                          : `${row.plan[0]?.name} ${
                              row.isPlanActive ? "✅" : "❌"
                            }`
                        : "N/A"} */}
                      {row.plan[0]?.name ? (
                        row.plan[0]?.name === "Free" ? (
                          row.plan[0]?.name
                        ) : row.isPlanActive ? (
                          <span style={{ color: "#1db01a" }} title="Active">
                            {row.plan[0]?.name}
                          </span>
                        ) : (
                          <span style={{ color: "orange" }} title="Inactive">
                            {row.plan[0]?.name}
                          </span>
                        )
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "160px",
                        paddingRight: "0",
                      }}
                    >
                      {row.interviewResult === "Pending" ? (
                        row.interviewResult
                      ) : row.interviewResult === "Accepted" ? (
                        <span style={{ color: "#1db01a" }}>
                          {row.interviewResult}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {row.interviewResult}
                        </span>
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "160px",
                        paddingRight: "0",
                      }}
                    >
                      {row.matches[0] ? row.matches[0] : "0"}
                    </TableCell>
                    <TableCell
                      style={{
                        // textAlign: "center",
                        width: "160px",
                        paddingInline: "0",
                      }}
                    >
                      {row.plan[0]?.name === "Free" ||
                      row.isPlanActive === false ||
                      row.interviewResult !== "Accepted" ? null : (
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "var(--primaryColor)",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            setSelectedUser(row._id);
                            navigate("/matches", {
                              state: { name: row.fullName },
                            });
                          }}
                        >
                          Find matches
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <KababMenu
                        getUsers={getUsers}
                        userId={row?._id}
                        isActive={row?.isActive}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={users?.userCount || 10}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default DataTable;

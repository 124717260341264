import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    id: null,
    token: null,
  },
  reducers: {
    userLogin: (state, action) => {
      state.isAuthenticated = true;
      state.id = action?.payload?.id;
      state.token = action?.payload?.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.id = null;
      state.token = null;
    },
    setUserId: (state, action) => {
      state.id = action?.payload?.id;
    },
    setToken: (state, action) => {
      state.token = action?.payload?.token;
    },
  },
});

export const { userLogin, logout, setUserId, setToken } = userSlice.actions;

export default userSlice.reducer;

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function SnackbarError({
  message,
  setOpenSnackbar,
  openSnackbar,
  TransitionComponent,
}) {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={4000} // Adjust the duration as needed
      onClose={() => setOpenSnackbar(false)}
      TransitionComponent={TransitionRight}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="error"
        onClose={() => setOpenSnackbar(false)}
      >
        {/* Incorrect username or password. */}
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default SnackbarError;

import React, { useEffect, useState } from "react";
import logo from "../images/inluvLogo.png";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { axiosCall } from "../utils/axiosCall";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import { setUserId } from "../redux/features/userSlice";
import Slide from "@mui/material/Slide";
import SnackbarError from "../components/SnackbarError";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const defaultTheme = createTheme();
function ForgotPass() {
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Inluv Admin | Forgot password";
  }, []);

  const handleSubmit = async () => {
    try {
      const otp = await axiosCall("POST", "/admin/v1/resendOtp", { email });
      if (otp.isSuccess) {
        dispatch(setUserId({ id: otp.data.data }));
        setOpenSuccessSnackbar(true);
        setTimeout(() => {
          navigate("/verify-mail");
        }, 3000);
      } else {
        setError(otp.error?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="h1" variant="h5" sx={{ fontWeight: "600" }}>
            Forgot Password
          </Typography>
          <Typography
            component="h2"
            variant="h6"
            sx={{ fontSize: "16px", mt: 2, textAlign: "center" }}
          >
            Enter your email address associated with your account.
          </Typography>
          <Box
            // component="form"
            // onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            // action="/verify-mail"
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, p: 1.5 }}
              style={{ backgroundColor: "var(--primaryColor)", color: "#fff" }}
              onClick={handleSubmit}
              disabled={!email}
            >
              Reset Password
            </Button>
          </Box>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSuccessSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSuccessSnackbar(false)}
            TransitionComponent={TransitionDown}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="success"
              onClose={() => setOpenSuccessSnackbar(false)}
            >
              OTP sent successfully!
            </MuiAlert>
          </Snackbar>
          <SnackbarError
            message={error}
            setOpenSnackbar={setOpenSnackbar}
            openSnackbar={openSnackbar}
          />
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ForgotPass;
